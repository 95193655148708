/* @__PURE__ */
export const quadraticOut = (t: number): number => {
    const x = 1 - t;
    return 1 - x * x;
};

/* @__PURE__ */
export const cubicOut = (t: number): number => {
    const x = 1 - t;
    return 1 - x * x * x;
};

const back = (t: number, overshoot: number) =>
    t >= 1 ? 1 : (t * t * ((overshoot + 1.0) * t - overshoot));

export const backOut = (t: number) => 1 - back(1 - t, 1.70158);
