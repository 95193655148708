import {drawText, drawTextAligned, fnt} from "./font.js";
import {inputPointers, mousePointer, Pointer} from "../utils/input.js";
import {img} from "../assets/gfx.js";
import {draw, gl, setDrawZ} from "./draw2d.js";
import {getScreenScale} from "../game/camera.js";
import {clamp, reach} from "../utils/math.js";
import {Img} from "../assets/img.js";
import {fxRandom} from "../utils/rnd.js";
import {cubicOut} from "../utils/easing.js";
import {play} from "../audio/context.js";
import {Snd, snd} from "../assets/sfx.js";

let y = 10;
export const resetPrinter = () => {
    y = 10;
};
export const termPrint = (text: string, size = 8) => {
    drawText(fnt[0], text, size, 5, y, size, 1);
    y += size + 4;
};

// https://sol.gfxile.net/imgui/ch03.html

let hotItem = "";
let activeItem = "!";
let pointer: Pointer | null = mousePointer;
let frameIndex = 0;

interface Widget {
    _id: string;
    _lastFrame: number;
    _tActive: number;
    _tHot: number;
    _t0: number;
}

interface OpaqueQuad {
    _x: number;
    _y: number;
    _w: number;
    _h: number;
    _a: number;
    _color: number;
}

interface TextOp {
    _x: number;
    _y: number;
    _size: number;
    _text: string;
    _alignX?: number;
}

export const uiState = {
    _scale: 1,
    _width: 1,
    _height: 1,
    _opaqueQuads: [] as OpaqueQuad[],
    _textOps: [] as TextOp[],
    _widgets: new Map<string, Widget>(),
};

export const uiQuad = (x: number, y: number, w: number, h: number, color: number, rotation: number) => {
    uiState._opaqueQuads.push({
        _x: x,
        _y: y,
        _w: w,
        _h: h,
        _color: color,
        _a: rotation,
    });
};

export const uiText = (text: string, x: number, y: number, size: number, alignX?: number, w?: number, h?: number) => {
    const ax = alignX ?? 0.5;
    if (w) {
        x += w * ax;
    }
    if (h) {
        y += h / 1.5;
    }
    uiState._textOps.push({
        _x: x,
        _y: y,
        _size: size,
        _text: text,
        _alignX: ax,
    });
};

const captureInputPointer = () => {
    pointer = mousePointer;
    for (const [, touchPointer] of inputPointers) {
        if (touchPointer._active || touchPointer._upEvent) {
            pointer = touchPointer;
            break;
        }
    }
};

export const ui_begin = () => {
    ++frameIndex;
    captureInputPointer();
    hotItem = "";
    setDrawZ(1000);
    uiState._scale = getScreenScale();
    uiState._width = (gl.drawingBufferWidth / uiState._scale) | 0;
    uiState._height = (gl.drawingBufferHeight / uiState._scale) | 0;
};

export const ui_finish = () => {
    if (!pointer._active) {
        activeItem = "";
    } else {
        if (!activeItem) {
            activeItem = "!";
        }
    }

    for (const [, w] of uiState._widgets) {
        w._tActive = reach(w._tActive, w._id === activeItem ? 1 : 0, 1 / 4);
        w._tHot = reach(w._tHot, w._id === hotItem ? 1 : 0, 1 / 4);
        if (frameIndex > w._lastFrame) {
            uiState._widgets.delete(w._id);
        }
    }
};

export const label = (text: string, size: number, x: number, y: number, alignX = 0.5) => {
    uiState._textOps.push({_x: x, _y: y, _size: size, _text: text, _alignX: alignX});
};

// Check whether current mouse position is within a rectangle
const isRegionHit = (x: number, y: number, w: number, h: number): number => {
    const px = pointer._x / uiState._scale;
    const py = pointer._y / uiState._scale;
    if (px < x || py < y || px >= x + w || py >= y + h) {
        return 0;
    }
    return 1;
};

const getRegionPointerX = (x: number, w: number): number => {
    const px = pointer._x / uiState._scale;
    return (px - x) / w;
};

export const button = (
    id: string,
    text: string,
    x: number,
    y: number,
    config?: {w?: number; h?: number; visible?: boolean; enabled?: boolean, icon?: string},
): number => {
    let widget = uiState._widgets.get(id);
    if (!widget) {
        widget = {
            _id: id,
            _lastFrame: 0,
            _tActive: 0,
            _tHot: 0,
            _t0: fxRandom(),
        };
        uiState._widgets.set(id, widget);
    }
    widget._lastFrame = frameIndex;
    const w = config?.w ?? 64;
    const h = config?.h ?? 16;
    const visible = config?.visible ?? true;
    const enabled = config?.enabled ?? true;
    const icon = config?.icon;
    if (isRegionHit(x, y, w, h) && enabled) {
        if (hotItem !== id) {
            hotItem = id;
            if (widget._tHot <= 0) {
                play(snd[Snd.ui_over], 1, 0);
            }
        }
        if (!activeItem && pointer._active) {
            activeItem = id;
            play(snd[Snd.ui_click], 1, 0);
        }
    }
    if (visible) {
        let offset = 0;
        let color = 0xffffff;

        if (hotItem === id) {
            if (activeItem === id) {
                // Button is both 'hot' and 'active'
                offset = cubicOut(widget._tActive * widget._tHot);
            } else {
                // Button is merely 'hot'
                offset = 0;
            }
        } else {
            // button is not hot, but it may be active
            color = enabled ? 0xaaaaaa : 0x888888;
        }
        const e1 = 4 * cubicOut(widget._tHot);
        const e0 = -e1 / 2;
        const a0 = 0.05 * (widget._t0 - 0.5) * widget._tHot;
        uiQuad(e0 + x + offset, e0 + y + offset, e1 + w, e1 + h, color, a0);
        uiQuad(e0 + x + 2, e0 + y + 2, e1 + w, e1 + h, 0, a0);
        let textX = x;
        let textW = w;
        if (icon) {
            uiText(icon, x + offset, y + offset, 8, 0.5, h, h);
            textX = x + h;
            textW = w - h;
        }
        if (text) {
            uiText(text, textX + offset, y + offset, 8, 0.5, textW, h);
        }
    }

    // DEBUG:
    // if (!visible && false) {
    //     uiQuad(x, y, w, h, 0x333333, 0.01);
    // }

    // If button is hot and active, but mouse button is not
    // down, the user must have clicked the button.
    if (!pointer._active && hotItem === id && activeItem === id && enabled) {
        return 1;
    }

    return 0;
};


export const uiProgressBar = (
    id: string,
    current: number,
    total: number,
    x: number,
    y: number,
    w: number,
    h: number,
): undefined | number => {
    if (isRegionHit(x, y, w, h)) {
        hotItem = id;
        if (!activeItem && pointer._active) {
            activeItem = id;
        }
    }
    const progress = current / total;
    uiQuad(x, y, w * progress, h, 0xcccccc, 0);
    uiQuad(x + w * progress, y, w * (1 - progress), h, 0x333333, 0);

    let r = getRegionPointerX(x, w);
    r = clamp(r, 0, 1);
    r = Math.round(r * total) / total;

    if (!pointer._active && hotItem === id && activeItem === id) {
        return r;
    }

    if (hotItem === id) {
        if (activeItem === id) {
            // Button is both 'hot' and 'active'
        } else {
            // Button is merely 'hot'
        }
        uiQuad(x, y - 1, w * r, 2, 0xFFFFFF, 0);
        return -r - 1;
    } else {
        // button is not hot, but it may be active
    }
};

export const ui_renderOpaque = () => {
    for (const q of uiState._opaqueQuads) {
        draw(img[Img.box], q._x + q._w / 2, q._y + q._h / 2, q._a, q._w, q._h, 1, q._color);
    }
};

export const ui_renderNormal = () => {
    for (const t of uiState._textOps) {
        drawTextAligned(fnt[0], t._text, t._size, t._x, t._y, 0xffffff, t._alignX);
    }
};

export const ui_renderComplete = () => {
    uiState._opaqueQuads.length = 0;
    uiState._textOps.length = 0;
};
