import {button} from "../graphics/gui.js";
import {
    BloodMode,
    DEFAULT_FRAMERATE_LIMIT,
    hasSettingsFlag,
    setSetting,
    Setting,
    SettingFlag,
    settings,
    toggleSettingsFlag,
} from "../game/settings.js";
import {modalPopup} from "../modals/index.js";
import {L} from "../assets/text.js";

export const guiSettingsPanel = (x: number, y: number) => {
    const sounds = hasSettingsFlag(SettingFlag.Sound);
    const music = hasSettingsFlag(SettingFlag.Music);
    if (
        button(
            "sounds",
            L("settings_sounds") + " " + (sounds ? "✓" : "□"),
            x - 50,
            y - 70,
            {
                icon: sounds ? "🔊" : "🔇",
                w: 100,
                h: 20,
            },
        )
    ) {
        toggleSettingsFlag(SettingFlag.Sound);
    }
    if (
        button(
            "music",
            L("settings_music") + " " + (music ? "✓" : "□"),
            x - 50,
            y - 40,
            {
                icon: "🎵",
                w: 100,
                h: 20,
            },
        )
    ) {
        toggleSettingsFlag(SettingFlag.Music);
    }
    if (
        button(
            "speech",
            L("settings_speech") + " " + (hasSettingsFlag(SettingFlag.Speech) ? "✓" : "□"),
            x - 50,
            y - 10,
            {
                icon: "💬",
                w: 100,
                h: 20,
            },
        )
    ) {
        toggleSettingsFlag(SettingFlag.Speech);
    }

    const bloodModeIcons = ["️‍🩹", "🩸", "🎨"];
    const bloodModeTextKey = ["settings_none", "fx_blood", "fx_paint"];
    if (
        button("blood", "FX: " + L(bloodModeTextKey[settings[Setting.Blood]]), x - 65, y + 20, {
            icon: bloodModeIcons[settings[Setting.Blood]],
            w: 80,
            h: 20,
        })
    ) {
        setSetting(Setting.Blood, ((settings[Setting.Blood] + 1) % 3) as BloodMode);
    }

    let particlesMod = settings[Setting.Particles];
    const particlesText = particlesMod > 0 ? "X" + particlesMod : L("settings_none");
    if (
        button("particles", particlesText, x + 25, y + 20, {
            icon: "✨",
            w: 40,
            h: 20,
        })
    ) {
        particlesMod *= 2;
        if (particlesMod <= 0) {
            particlesMod = 0.5;
        }
        if (particlesMod > 4) {
            particlesMod = 0;
        }
        setSetting(Setting.Particles, particlesMod);
    }
    if (
        button(
            "highDPI",
            L("settings_high_dpi") + " " + (hasSettingsFlag(SettingFlag.HighDPI) ? "✓" : "□"),
            x - 85,
            y + 50,
            {
                icon: "🖥️",
                w: 80,
                h: 20,
            },
        )
    ) {
        toggleSettingsFlag(SettingFlag.HighDPI);
    }

    const frameRateCap = settings[Setting.FrameRateCap];
    if (
        button(
            "frameRateCap",
            L("settings_fps_limit") + ": " + (frameRateCap > 0 ? frameRateCap + "hz" : "OFF"),
            x + 5,
            y + 50,
            {
                w: 80,
                h: 20,
            },
        )
    ) {
        setSetting(Setting.FrameRateCap, frameRateCap > 0 ? 0 : DEFAULT_FRAMERATE_LIMIT);
    }

    const antialiasing = hasSettingsFlag(SettingFlag.Antialiasing);
    if (
        button("antialiasing", L("settings_aa") + " " + (antialiasing ? "✓" : "□"), x - 50, y + 80, {
            icon: "𓊍",
            w: 100,
            h: 20,
        })
    ) {
        modalPopup({
            title: L("popup_aa_title"),
            desc: L("popup_aa_desc"),
        })
            .then(() => {
                toggleSettingsFlag(SettingFlag.Antialiasing);
                location.reload();
            })
            .catch(() => {
                //
            });
    }
};
