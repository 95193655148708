import {logUserEvent} from "../../platforms/web/index.js";
import {connect, disconnect} from "../../net/messaging.js";
import {GameModeFlag} from "@iioi/shared/types.js";
import {game, gameMode, getPlayerByClient, pushActor, requireStats} from "../gameState.js";
import {button, label, uiState} from "../../graphics/gui.js";
import {Actor, ItemActor, ItemType} from "../types.js";
import {GAME_CFG} from "../config.js";
import {newItemActor, newPlayerActor} from "../actors.js";
import {addPos, checkTileCollisions, collideWithBoundsA, copyPosFromActorCenter} from "../phy.js";
import {WORLD_BOUNDS_SIZE_PX} from "../../assets/params.js";
import {TILE_SIZE_BITS} from "../tilemap.js";
import {fxRandom} from "../../utils/rnd.js";
import {cos, PI2, sin} from "../../utils/math.js";
import {getUserString, setUserString} from "../../utils/storage.js";
import {L} from "../../assets/text.js";
import {hasMousePointer} from "../../utils/input.js";

const TEST_TUTORIAL = false;
let step = 0;
let stepProgress = 0;
let knifeItem: ItemActor;
let enemies: Actor[] = [];

export const initTutorial = () => {
    game._mapSpawnSlots = [{
        _x: (WORLD_BOUNDS_SIZE_PX >> 1) >> TILE_SIZE_BITS,
        _y: (WORLD_BOUNDS_SIZE_PX >> 1) >> TILE_SIZE_BITS,
        _type: 2,
    }];
};

const completeTutorial = () => {
    game._tutorial = false;
    step = 0;
    setUserString("tutorial", "1");
    logUserEvent("tutorial_completed");
    disconnect();
};

export const startFirstTutorial = () => {
    const isTutorialCompleted = getUserString("tutorial");
    if (!isTutorialCompleted || TEST_TUTORIAL) {
        step = 1;
        logUserEvent("start_tutorial");
        connect({
            _flags: GameModeFlag.Offline,
            _npcLevel: 0,
            _playersLimit: 1,
            _theme: 0,
        });
        gameMode._npcLevel = 0;
        gameMode._offline = true;
        game._tutorial = true;
        return true;
    }
    return false;
};

const incTutorialStep = () => {
    logUserEvent("tutorial_step_completed_" + step);
    ++step;
    stepProgress = 0;
};

export const onTutorialUpdate = () => {
    if (step) {
        const player = getPlayerByClient(1);

        const spawnEnemies = () => {
            for (let i = 0; i < 5; ++i) {
                let N = 1000;
                const enemy = newPlayerActor();
                while (N) {
                    enemy._u = enemy._v = enemy._w = 0;
                    copyPosFromActorCenter(enemy, player);
                    const angle = fxRandom(PI2);
                    addPos(enemy, 3000 * cos(angle), 3000 * sin(angle), 0);
                    if (!collideWithBoundsA(enemy) && !checkTileCollisions(enemy, game._blocks)) {
                        break;
                    }
                    --N;
                }
                if (!N) {
                    copyPosFromActorCenter(enemy, player);
                    enemy._u = enemy._v = enemy._w = 0;
                }
                enemy._hp = 10;
                enemy._mags = 0;
                pushActor(enemy);
                enemies.push(enemy);
            }
        };

        const keepImmortal = () => {
            if (player) {
                player._hp = 10;
                player._sp = 10;
                player._mags = 10;
                enemies = enemies.filter(e => !!e._hp);
                if (!enemies.length) {
                    spawnEnemies();
                }
            } else {
                step = 0;
                disconnect();
            }
        };

        if (step === 1) {
            if (player) {
                player._weapon2 = 0;
                player._weapon = 0;
                player._mags = 0;
                //player._x = (BOUNDS_SIZE / 2 + D * cos(k * PI2)) * WORLD_SCALE
                //player._x = (BOUNDS_SIZE / 2 + D * cos(k * PI2)) * WORLD_SCALE
                // player._x = (BOUNDS_SIZE / 2) * WORLD_SCALE;
                // player._y = (BOUNDS_SIZE / 2) * WORLD_SCALE;
                // player._z = 10;
                incTutorialStep();
            }
        } else if (step === 2) {
            if (player._u || player._v) {
                ++stepProgress;
            }
            if (player._z > 10) {
                ++stepProgress;
            }
            if (stepProgress > 100) {
                incTutorialStep();
                knifeItem = newItemActor(ItemType.Weapon);
                pushActor(knifeItem);
                knifeItem._itemWeapon = 1;
                copyPosFromActorCenter(knifeItem, player);
            }
        } else if (step === 3) {
            if (knifeItem && knifeItem._subtype) {
                knifeItem._lifetime = GAME_CFG.items.lifetime;
            } else {
                incTutorialStep();
                spawnEnemies();
                // hold enemies!
                gameMode._runAI = false;
            }
        } else if (step === 4) {
            keepImmortal();
            const stat = requireStats(1);
            if (stat._frags === 1) {
                player._weapon = 3;
            } else if (stat._frags === 3) {
                player._weapon = 6;
            } else if (stat._frags === 5) {
                incTutorialStep();
                gameMode._runAI = true;
            }
        } else if (step === 5) {
            // ++stepProgress;
            // if(stepProgress === 5) {
            //     spawnEnemies();
            //     stepProgress = 0;
            // }
            keepImmortal();
        }
    }
};

export const onTutorialUI = () => {
    if (step) {
        const W = uiState._width;
        const touchpad = !hasMousePointer();
        const titleY = 16;
        const descY = 40;
        switch (step) {
            case 2:
                label(L("tutorial_2"), 14, W / 2, titleY);
                if (touchpad) {
                    label(L("tutorial_2_mob_1") + "\n" + L("tutorial_2_mob_2"), 10, W / 2, descY);
                } else {
                    label(L("tutorial_2_pc_1") + "\n" + L("tutorial_2_pc_2"), 10, W / 2, descY);
                }
                if (stepProgress > 0) {
                    label(stepProgress + "%", 8, W / 2, descY + 30);
                }
                break;
            case 3:
                label(L("tutorial_3"), 14, W / 2, titleY);
                if (touchpad) {
                    label(L("tutorial_3_mob"), 10, W / 2, descY);
                } else {
                    label(L("tutorial_3_pc"), 10, W / 2, descY);
                }
                break;
            case 4:
                label(L("tutorial_4"), 14, W / 2, titleY);
                if (touchpad) {
                    label(L("tutorial_4_mob"), 10, W / 2, descY);
                } else {
                    label(L("tutorial_4_pc"), 10, W / 2, descY);
                }
                break;
            case 5:
                label(L("tutorial_you_are_ready"), 14, W / 2, titleY);
                if (button("tutorial_complete", "🏁 " + L("tutorial_complete"), W / 2 - 50, 30, {w: 100, h: 20})) {
                    completeTutorial();
                }
                break;
        }
        if (step < 5) {
            if (button("tutorial_skip", "⏩ " + L("tutorial_skip"), W - 55, 10, {w: 45, h: 15})) {
                completeTutorial();
            }
        }
    }
};

export const isTutorialRunning = () => !!step;
export const getTutorialStep = () => step;

