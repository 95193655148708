const memory = new Map<string, string>();
const prefix = "iioi_";

export const getUserString = (key: string): string | undefined | null => {
    try {
        return localStorage.getItem(prefix + key);
    } catch {
        // ignore: possible incognito mode
    }
    return memory.get(key);
};

export const setUserString = (key: string, value: string): void => {
    try {
        localStorage.setItem(prefix + key, value);
    } catch {
        // ignore: possible incognito mode
    }
    memory.set(key, value);
};

