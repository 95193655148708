import {button, label, uiQuad, uiState} from "../graphics/gui.js";
import {L} from "../assets/text.js";
import {clientUserData, setUserName} from "../net/messaging.js";
import {modalPopup} from "../modals/index.js";
import {EMOJI} from "../assets/gfx.js";
import {Img} from "../assets/img.js";
import {setSetting, Setting, settings} from "../game/settings.js";
import {GAME_CFG} from "../game/config.js";
import {cos, sin} from "../utils/math.js";
import {lastFrameTs} from "../game/gameState.js";
import {poki} from "../poki.js";

export const uiCharacterMenu = (): void => {
    const W = uiState._width;
    const H = uiState._height;
    const centerX = W >> 1;
    const centerY = H >> 1;

    label("Character", 10, centerX, 20);
    if (button("change_name", clientUserData.name, centerX - 50, 40, {
        icon: "✏",
        w: 100,
        h: 20,
    })) {
        modalPopup({
            title: L("your_name"),
            desc: L("change_name_desc"),
            value: clientUserData.name,
        })
            .then(v => {
                setUserName(v);
            })
            .catch(() => {
                //
            });
    }
    {
        const unlockMask = settings[Setting.UnlockAvatar];
        for (let i = 0; i < Img.num_avatars; ++i) {
            const x = centerX - 75 + (i % 5) * 30;
            const y = centerY - 50 + 30 * ((i / 5) | 0);
            const avatarEmoji = EMOJI[Img.avatar0 + i];
            const unlockFlag = 1 << i;
            const unlocked = (i < 5) || (unlockMask & unlockFlag);
            if (button("head_" + i, avatarEmoji + (!unlocked ? "🎬" : ""), x, y, {
                w: 20,
                h: 20,
            })) {
                if (unlocked) {
                    clientUserData.avatar = setSetting(Setting.UserAvatar, i);
                } else {
                    poki._rewardedBreak().then((rewarded) => {
                        if (rewarded) {
                            setSetting(Setting.UnlockAvatar, unlockMask | unlockFlag);
                            clientUserData.avatar = setSetting(Setting.UserAvatar, i);
                        }
                    });
                }
            }
            if (i === clientUserData.avatar) {
                label("✓", 8, x + 10, y + 24);
            }
        }
    }
    {
        const y = centerY + 60;
        let x = centerX - (GAME_CFG.bodyColor.length * 25) / 2;
        const unlockMask = settings[Setting.UnlockBodyColor];
        for (let i = 0; i < GAME_CFG.bodyColor.length; ++i) {
            const avatarBodyColor = GAME_CFG.bodyColor[i];
            const unlockFlag = 1 << i;
            const unlocked = (i < 3) || (unlockMask & unlockFlag);
            uiQuad(x + 2, y + 2, 20 - 4, 20 - 4, avatarBodyColor, 0.2 * cos(5 * lastFrameTs) * sin(10 * lastFrameTs));
            if (button("body" + i, unlocked ? "" : "🎬", x, y, {
                w: 20,
                h: 20,
            })) {
                if (unlocked) {
                    clientUserData.body = setSetting(Setting.UserBodyColor, i);
                } else {
                    poki._rewardedBreak().then((rewarded) => {
                        if (rewarded) {
                            setSetting(Setting.UnlockBodyColor, unlockMask | unlockFlag);
                            clientUserData.body = setSetting(Setting.UserBodyColor, i);
                        }
                    });
                }
            }
            if (i === clientUserData.body) {
                label("✓", 8, x + 10, y + 14);
            }
            x += 25;
        }
    }
};

export const uiCharacterMain = (): boolean => {
    const W = uiState._width;
    const centerX = W >> 1;

    label(L("welcome_back"), 7, centerX - 38, 16, 0);
    label(clientUserData.name, 8, centerX - 38, 26, 0);
    const avatarEmoji = EMOJI[Img.avatar0 + (clientUserData.avatar % Img.num_avatars)];
    const avatarBodyColor = GAME_CFG.bodyColor[clientUserData.body % GAME_CFG.bodyColor.length];
    uiQuad(centerX - 64 + 2, 8 + 2, 20 - 4, 20 - 4, avatarBodyColor, 0.2 * cos(5 * lastFrameTs) * sin(10 * lastFrameTs));
    if (button("avatar", avatarEmoji, centerX - 64, 8, {
        w: 20,
        h: 20,
    })) {
        return true;
    }
    if (button("main_change_name", "", centerX - 34, 10, {w: 80, h: 20, visible: false})) {
        modalPopup({
            title: L("your_name"),
            desc: L("change_name_desc"),
            value: clientUserData.name,
        })
            .then(v => {
                setUserName(v);
            })
            .catch(() => {
                //
            });
    }
    return false;
};
