import {button, label, ui_begin, ui_finish, uiQuad, uiState} from "../graphics/gui.js";
import {keyboardDown, KeyCode} from "../utils/input.js";
import {_room, clientId, disconnect} from "../net/messaging.js";
import {Const, GAME_CFG, RESPAWN_TIME_SECONDS} from "./config.js";
import {guiSettingsPanel} from "../screens/settingsPanel.js";
import {saveReplay} from "./replay/recorder.js";
import {poki} from "../poki.js";
import {
    game,
    GameMenuState,
    gameMode,
    requireStats,
    SHOP_PERK_PRICE,
    SHOP_REWARD_BONUS,
    SHOP_START_PRICE,
} from "@iioi/client/game/gameState.js";
import {guiReplayViewer} from "./replay/viewer.js";
import {logScreenView} from "../platforms/web/index.js";
import {L} from "../assets/text.js";
import {ControlsFlag} from "./types.js";
import {isTutorialRunning, onTutorialUI} from "./tutorial/tutorial.js";

let linkCopied = false;

export const onGameMenu = (gameTic?: number): void => {
    ui_begin();
    onTutorialUI();
    {
        const W = uiState._width;
        const H = uiState._height;
        const centerX = W >> 1;
        const centerY = H >> 1;

        if (gameMode._menu === GameMenuState.InGame && !isTutorialRunning()) {
            if (gameMode._replay && gameTic != null) {
                guiReplayViewer(gameMode._replay, gameTic);
            } else {
                if (
                    button("menu", "⏸️", W - 25, GAME_CFG.minimap.size + GAME_CFG.minimap.offset + 5, {
                        w: 20,
                        h: 20,
                    }) ||
                    keyboardDown[KeyCode.Escape]
                ) {
                    poki._gameplayStop();
                    gameMode._menu = GameMenuState.Paused;
                }
            }
        } else if (gameMode._menu === GameMenuState.Paused) {
            let y = centerY - 120;
            if (button("save-replay", L("save_replay"), centerX - 50, y, {icon: "💾", w: 100, h: 20})) {
                saveReplay();
            }
            y += 25;
            if (!gameMode._offline &&
                button("copy_link", linkCopied ? L("link_copied") : L("copy_link"), centerX - 50, y, {
                    icon: "🔗",
                    w: 100,
                    h: 20,
                })
            ) {
                if (!linkCopied) {
                    poki._shareableURL({r: _room._code})
                        .then(url => navigator.clipboard.writeText(url))
                        .then(() => {
                            linkCopied = true;
                            setTimeout(() => (linkCopied = false), 3000);
                        });
                }
            }

            y = centerY + 40;
            if (button("settings", "⚙️ " + L("settings_title"), centerX - 50, y, {w: 100, h: 20})) {
                gameMode._menu = GameMenuState.Settings;
                logScreenView("settings_screen");
            }
            y += 25;
            if (button("quit_room", "🏃 " + L("quit_game_room"), centerX - 50, y, {w: 100, h: 20})) {
                disconnect();
            }
            y += 25;
            if (
                button("back_to_game", "⬅ " + L("back"), centerX - 50, y, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                poki._gameplayStart();
                gameMode._menu = GameMenuState.InGame;
            }
        } else if (gameMode._menu === GameMenuState.Settings) {
            guiSettingsPanel(centerX, centerY - 20);

            if (
                button("back", "⬅ " + L("back"), centerX - 50, centerY + 90, {
                    w: 100,
                    h: 20,
                }) ||
                keyboardDown[KeyCode.Escape]
            ) {
                gameMode._menu = GameMenuState.Paused;
            }
        } else if (gameMode._menu === GameMenuState.Respawn) {
            let y = 20;
            // top:
            // label(`☠️ ${L("respawn_title")} ☠️`, 14, centerX, y, 0.5);

            y += 14;
            const canMutate = !game._mutateInput;
            const stat = requireStats(clientId);
            const frags = stat._frags;
            const coins = stat._coins;
            label(`🪙 ${coins}`, 12, centerX + 20 - 4, y + 14, 0);
            label(`☠ ${frags}`, 12, centerX - 60 - 4, y + 14, 0);

            uiQuad(centerX - 60 - 4, y, 50 + 8, 20, 0x333333, 0.01);
            uiQuad(centerX + 20 - 4, y, 50 + 8, 20, 0x333333, -0.01);
            uiQuad(centerX - 60 - 4 + 2, y + 2, 50 + 8 + 2, 20, 0, 0.02);
            uiQuad(centerX + 20 - 4 + 2, y + 2, 50 + 8 + 2, 20, 0, -0.02);

            {
                y = centerY - 45;
                const x = centerX + 30;
                label(`+${SHOP_REWARD_BONUS} 🪙`, 8, x + 40 / 2, y + 8, 0.5);
                if (button("more_coins", L("rewarded_video"), x, y + 14, {
                    icon: "🎬",
                    w: 40,
                    h: 14 + 4,
                    enabled: canMutate && (coins + SHOP_REWARD_BONUS < 0xFFFF),
                })) {
                    poki._rewardedBreak().then((rewarded) => {
                        if (rewarded) {
                            game._mutateInput = ControlsFlag.Mutate_RewardCoins;
                        }
                    });
                }
                uiQuad(x - 4, y - 4, 40 + 8, 30 + 8, 0x333333, 0.01);
                uiQuad(x - 4 - 2, y - 4 - 2, 40 + 8 + 4, 30 + 8 + 4, 0xAAFF33, -0.02);
            }

            const drawBuyCard = (id: string, icon: string, price: number, current: number, max: number, x: number, y: number, mutation: number) => {
                x += centerX;
                label(current + "/" + max, 8, x + 10, y - 3, 0.5);
                label(`🪙${price}`, 8, x + 10, y + 27, 0.5);
                if (button(id, "+" + icon, x, y, {
                    w: 20,
                    h: 20,
                    enabled: canMutate && (coins >= price) && (current < max),
                })) {
                    game._mutateInput = mutation;
                }
                uiQuad(x - 4, y - 8, 20 + 4 + 4, 20 + 12, 0x339966, -0.05);
                uiQuad(x - 4 - 1, y - 8 - 1, 20 + 4 + 4 + 2, 20 + 12 + 2, 0xCCCCCC, -0.07);
            };

            const drawUpgradeCard = (id: string, icon: string, desc: string, price: number, current: number, max: number, x: number, y: number, mutation: number) => {
                x += centerX;
                //label(`${icon} ${current} lvl`, 5, x + 10, y - 8, 0.5);
                label(desc, 8, x + 10, y - 3, 0.5);
                label(`🪙${price}`, 8, x + 10, y + 27, 0.5);
                if (button(id, icon + " " + (current + 1), x, y, {
                    w: 20,
                    h: 20,
                    enabled: canMutate && (coins >= price) && (current < max),
                })) {
                    game._mutateInput = mutation;
                }
                uiQuad(x - 4, y - 8, 20 + 4 + 4, 20 + 12, 0x336699, 0.05);
                uiQuad(x - 4 - 1, y - 8 - 1, 20 + 4 + 4 + 2, 20 + 12 + 2, 0xCCCCCC, 0.07);
            };
            y = centerY - 35;
            {
                const ox = -40;
                label(L("respawn_start_with") + ":", 8, centerX + ox, y - 16, 0.5);
                let x = -30 + ox;
                drawBuyCard("buy_shield", "🛡", SHOP_START_PRICE, stat._sp0, 10, x, y, ControlsFlag.Mutate_StartSp);
                x = 10 + ox;
                drawBuyCard("buy_mag", "🧱", SHOP_START_PRICE, stat._mag0 + 1, 10, x, y, ControlsFlag.Mutate_StartMag);
                uiQuad(ox + centerX - 50, y - 24, 100, 56, 0x333333, 0.01);
                uiQuad(ox + centerX - 50 + 2, y - 24 + 2, 100, 56, 0, 0.02);
            }
            y += 60;
            {
                label(L("respawn_upgrades") + ":", 8, centerX, y - 16, 0.5);
                let x = -75;
                drawUpgradeCard("buy_velocity", "🏃", L("upgrade_velocity"), SHOP_PERK_PRICE * (1 + stat._vel), stat._vel, 10, x, y, ControlsFlag.Mutate_Velocity);
                x += 40;
                drawUpgradeCard("buy_damage", "💥", L("upgrade_damage"), SHOP_PERK_PRICE * (1 + stat._damage), stat._damage, 10, x, y, ControlsFlag.Mutate_Damage);
                x += 40;
                drawUpgradeCard("buy_agility", "🧘", L("upgrade_agility"), SHOP_PERK_PRICE * (1 + stat._agility), stat._agility, 10, x, y, ControlsFlag.Mutate_Agility);
                x += 40;
                drawUpgradeCard("buy_armor", "🧽", L("upgrade_armor"), SHOP_PERK_PRICE * (1 + stat._armor), stat._armor, 10, x, y, ControlsFlag.Mutate_Armor);
                uiQuad(centerX - 100, y - 24, 200, 56, 0x333333, -0.01);
                uiQuad(centerX - 100 + 2, y - 24 + 2, 200, 56, 0, -0.02);
            }
            // bottom:
            y = H - 60;
            {
                const respawnTimeSeconds = RESPAWN_TIME_SECONDS;
                const respawnTimer = (gameTic - gameMode._respawnStartTic) / Const.NetFq;
                const v = ((respawnTimer / respawnTimeSeconds) * 100) | 0;
                const allow = !poki._isBreakActive() && canMutate && game._allowedToRespawn && respawnTimer > respawnTimeSeconds;
                const respawning = respawnTimer <= respawnTimeSeconds;
                if (
                    button("respawn", respawning ? `${L("respawn")} ${v}%` : L("start_fight"), centerX - 50, y, {
                        icon: respawning ? "🔄" : "⚔",
                        w: 100,
                        h: 20,
                        enabled: allow,
                    }) ||
                    (allow && (keyboardDown[KeyCode.Escape] || keyboardDown[KeyCode.Space]))
                ) {
                    poki._commercialBreak().then(() => {
                        gameMode._menu = GameMenuState.InGame;
                        game._waitToAutoSpawn = true;
                    });
                }
            }
            y += 30;
            if (button("quit_room", L("quit_game_room"), centerX - 50, y, {icon: "🏃", w: 100, h: 20})) {
                disconnect();
            }
        }
    }
    ui_finish();
};
