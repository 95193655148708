import {inputPointers, keyboardState, KeyCode, mousePointer, Pointer} from "../utils/input.js";
import {fillCircle, strokeCircle, gl} from "../graphics/draw2d.js";
import {ItemType, PlayerActor} from "./types.js";
import {
    PAD_FIRE_RADIUS_0,
    PAD_FIRE_RADIUS_1,
    PAD_MOVE_RADIUS_0,
    PAD_MOVE_RADIUS_1,
    WORLD_SCALE,
} from "../assets/params.js";
import {gameCamera, getScreenScale} from "./camera.js";
import {hypot} from "../utils/math.js";
import {drawTextAligned, fnt} from "../graphics/font.js";
import {GAME_CFG} from "./config.js";
import {game} from "./gameState.js";

// TODO: positioning of controls
// ToDO: control zone padding should include max radius
// TODO: return mouse control
// TODO: combine pad + keyboard
export let lookAtX = 0;
export let lookAtY = 0;
export let viewX = 0;
export let viewY = 0;
export let moveX = 0;
export let moveY = 0;
export let shootButtonDown = false;
export let jumpButtonDown = false;
export let moveFast = false;
export let dropButton = false;
export let reloadButton = false;
export let swapButton = false;

export const resetPlayerControls = () => {
    moveX = 0;
    moveY = 0;
    shootButtonDown = false;
    jumpButtonDown = false;
    moveFast = false;
    dropButton = false;
    reloadButton = false;
    swapButton = false;
};

export const couldBeReloadedManually = (player: PlayerActor): boolean => {
    const weapons = GAME_CFG.weapons;
    const weapon = weapons[player._weapon];
    return weapon && !player._clipReload && weapon.clipSize && player._clipAmmo < weapon.clipSize;
};

export const couldSwapWeaponSlot = (player: PlayerActor): boolean => {
    return !!player._weapon2;
};

export const couldPickUp = (): boolean => {
    return game._hotUsable && !!(game._hotUsable._subtype & ItemType.Weapon);
};

export const couldDrop = (player: PlayerActor): boolean => {
    return !!player._weapon2;
};

export const updateControls = (player: PlayerActor) => {
    const W = gl.drawingBufferWidth;
    const H = gl.drawingBufferHeight;

    const mouse = mousePointer;

    const px = player._x / WORLD_SCALE;
    const py = (player._y - player._z) / WORLD_SCALE - 10;

    if (mouse._x >= 0 && mouse._x < W && mouse._y >= 0 && mouse._y < H) {
        lookAtX = (mouse._x - W / 2) * gameCamera._scale + gameCamera._x;
        lookAtY = (mouse._y - H / 2) * gameCamera._scale + gameCamera._y;
        viewX = lookAtX - px;
        viewY = lookAtY - py;
    } else {
        viewX = 0;
        viewY = 0;
    }

    shootButtonDown = (viewX || viewY) && mouse._active;

    moveX =
        (keyboardState[KeyCode.D] | keyboardState[KeyCode.Right]) -
        (keyboardState[KeyCode.A] | keyboardState[KeyCode.Left]);
    moveY =
        (keyboardState[KeyCode.S] | keyboardState[KeyCode.Down]) -
        (keyboardState[KeyCode.W] | keyboardState[KeyCode.Up]);

    //if (moveX || moveY) {
    moveFast = !keyboardState[KeyCode.Shift];
    //}

    jumpButtonDown = !!keyboardState[KeyCode.Space];
    dropButton = !!keyboardState[KeyCode.E];
    reloadButton = !!keyboardState[KeyCode.R];
    swapButton = !!keyboardState[KeyCode.Q];

    vpad[2]._text1 = couldPickUp() ? "PICK" : "DROP";
    vpad[2]._hidden = !(couldDrop(player) || couldPickUp());
    vpad[3]._hidden = !couldBeReloadedManually(player);
    vpad[4]._hidden = !couldSwapWeaponSlot(player);
    if (updateVirtualPad()) {
        const k = gameCamera._scale;
        let control = vpad[0];
        let pp = control._pointer;
        moveX = pp ? (pp._x - pp._startX) * k : 0;
        moveY = pp ? (pp._y - pp._startY) * k : 0;
        let len = hypot(moveX, moveY);
        moveFast = len > control._r1;
        jumpButtonDown = len > control._r2;

        control = vpad[1];
        pp = control._pointer;
        viewX = (pp ? (pp._x - pp._startX) : (control._lastDeltaX ?? 0)) * k * 1.2;
        viewY = (pp ? (pp._y - pp._startY) : (control._lastDeltaY ?? 0)) * k * 1.2;
        len = hypot(viewX, viewY);
        lookAtX = px + viewX * 2;
        lookAtY = py + viewY * 2;
        shootButtonDown = pp && len > control._r2;

        dropButton = !!vpad[2]._pointer;
        reloadButton = !!vpad[3]._pointer;
        swapButton = !!vpad[4]._pointer;
    }
};

interface VPadControl {
    // size
    _w: number;
    _h: number;
    // 0 - left, 1 - right
    _align: number;
    // offset from bottom
    _yo: number;
    // offset from edge
    _xo: number;

    _isButton?: number;
    _pointer?: Pointer | undefined;
    _hidden?: boolean;
    // any len > undefined = false (undefined is NaN)
    _r1?: number | undefined;
    _r2?: number | undefined;
    _text1?: string;
    _text2?: string;
    _lastDeltaX?: number;
    _lastDeltaY?: number;
}

const fixStartPos = (control:VPadControl, p:Pointer, scale: number, w: number, h: number) => {
    const w_ = control._w;
    const h_ = control._h;
    const cx = (control._xo + (scale * control._align * w) - 2 * control._xo * control._align) + w_ / 2;
    const cy = (scale * h) - control._yo + h_ / 2;
    const r1 = control._r1;

    let dx = scale * p._startX - cx;
    let dy = scale * p._startY - cy;
    const len = hypot(dx, dy);
    if(len > r1) {
        dx *= r1 / len;
        dy *= r1 / len;
        p._startX = (cx + dx) / scale;
        p._startY = (cy + dy) / scale;
    }
}

const vpad: VPadControl[] = [
    {_xo: 0, _yo: 128, _align: 0, _w: 128, _h: 128, _r1: PAD_MOVE_RADIUS_0, _r2: PAD_MOVE_RADIUS_1, _text1: "RUN", _text2: "JUMP"},
    {_xo: 128, _yo: 128, _align: 1, _w: 128, _h: 128, _r1: PAD_FIRE_RADIUS_0, _r2: PAD_FIRE_RADIUS_1, _text1: "AIM", _text2: "FIRE"},
    {_xo: 128, _yo: 128 + 40, _align: 1, _w: 40, _h: 40, _isButton: 1, _r1: 16, _text1: "DROP"},
    {_xo: 128 - 40, _yo: 128 + 40, _align: 1, _w: 40, _h: 40, _isButton: 1, _r1: 16, _text1: "RELOAD"},
    {_xo: 128 - 40 * 2, _yo: 128 + 40, _align: 1, _w: 40, _h: 40, _isButton: 1, _r1: 16, _text1: "SWAP"},
];

let touchPadActive = false;

const checkPointerIsAvailableForCapturing = (pointer: Pointer) => !vpad.some(c => c._pointer == pointer);

const testZone = (control: VPadControl, x: number, y: number, scale: number, w: number, h: number) => {
    const l = (control._xo + (scale * control._align * w) - 2 * control._xo * control._align);
    const t = (scale * h) - control._yo;
    const r = l + control._w;
    const b = t + control._h;
    x *= scale;
    y *= scale;
    return x > l && x < r && y > t && y < b;
};

const updateVirtualPad = () => {
    const W = gl.drawingBufferWidth;
    const H = gl.drawingBufferHeight;
    const k = 1 / getScreenScale();

    for (const control of vpad) {
        // if not captured
        if (!control._pointer) {
            // capture
            for (const [, p] of inputPointers) {
                if (
                    p._downEvent &&
                    testZone(control, p._startX, p._startY, k, W, H) &&
                    checkPointerIsAvailableForCapturing(p)
                ) {
                    control._pointer = p;
                    fixStartPos(control, p, k, W, H);
                }
            }
        }
        // if captured
        if (control._pointer) {
            const p = control._pointer;
            let release = !p._active;
            // out-of-zone mode
            if (control._isButton) {
                release ||= !testZone(control, p._x, p._y, k, W, H);
            }
            if (release) {
                // release
                control._pointer = undefined;
            } else {
                touchPadActive = true;
                control._lastDeltaX = p._x - p._startX;
                control._lastDeltaY = p._y - p._startY;
            }
        }
    }

    if (mousePointer._downEvent) {
        touchPadActive = [...inputPointers.values()].some(p => p._active);
        // [...a.values()].some(p=>p.b);
        // for(let [,p] of a) r|=p.v;
    }
    return touchPadActive;
};

export const drawVirtualPad = () => {
    if (!touchPadActive) {
        return;
    }
    const boxTexture = fnt[0]._textureBox;
    const W = gl.drawingBufferWidth;
    const H = gl.drawingBufferHeight;
    const k = 1 / getScreenScale();
    const segments1 = 12;
    const segments2 = 16;
    for (const control of vpad) {
        if (!control._hidden) {
            const w_ = control._w;
            const h_ = control._h;
            let cx = (control._xo + (k * control._align * W) - 2 * control._xo * control._align) + w_ / 2;
            let cy = (k * H) - control._yo + h_ / 2;
            const pp = control._pointer;
            if (!control._isButton && pp) {
                cx = pp._startX * k;
                cy = pp._startY * k;
                fillCircle(boxTexture, pp._x * k, pp._y * k, 16, segments1, 1, 1, 0.5);
            }
            if (control._r1 !== undefined) {
                drawTextAligned(fnt[0], control._text1, 8, cx, cy - control._r1 - 4, pp ? 0xffffff : 0x777777);
                strokeCircle(boxTexture, cx, cy, control._r1 - 2, 4, segments1, 1, 1, 0.5, pp ? 0xffffff : 0);
            }
            if (control._r2 !== undefined) {
                drawTextAligned(fnt[0], control._text2, 8, cx, cy - control._r2 - 4, pp ? 0xffffff : 0x777777);
                strokeCircle(boxTexture, cx, cy, control._r2 - 2, 4, segments2, 1, 1, 0.5, pp ? 0xffffff : 0);
            }
        }
    }
};
